






















































































































import Vue from "vue";
export default Vue.extend({
	data() {
		return {
			clubs: [] as any[]
		};
	},
	created() {
		this.$store.commit("pushLoading", {
			name: "GET_ALL_CLUB",
			message: "로그인 중"
		});
		this.$store
			.dispatch("GET_ALL_CLUB")
			.then(clubs => {
				this.$store.commit("clearLoading", "GET_ALL_CLUB");
				this.clubs = clubs;
			})
			.catch(err => {});
	},
	methods: {
		getImgPath(imgPath: string) {
			if (imgPath) return this.$store.state.mainPath + imgPath;
			else return "https://pbs.twimg.com/profile_images/770139154898382848/ndFg-IDH_400x400.jpg";
		},
		checkPermission(_club: any, permission: any, next: string) {
			this.$store.state.club = _club;
			if (permission == "admin") {
				if (this.isAdmin) {
					this.$router.push(`${_club.name}/${next}`);
				} else {
					this.$store.commit("showNotice", `권한이 없습니다.`);
				}
			} else {
				if (this.$store.state.club.ranks) {
					let user = this.$store.state.club.members.find((member: any) => {
						if (member.user == this.$store.state.userInformation._id) {
							return this.$router.push(`${_club.name}/${next}`);
						} else {
							return this.$store.commit("showNotice", `권한이 없습니다.`);
						}
					});
					if (
						user &&
						(this.$store.state.club.ranks.find((rank: any) => rank.id == user.rank).isAdmin() ||
							this.$store.state.club.ranks
								.find((rank: any) => rank.id == user.rank)
								.parseInt(permission)
								.indexOf("" + parseInt(permission)) != -1)
					) {
						return this.$router.push(`${_club.name}/${next}`);
					}
				} else {
					return this.$store.commit("showNotice", `권한이 없습니다.`);
				}
			}
		}
	},
	computed: {
		getRank() {
			if (this.$store.state.club.name) {
				try {
					return this.$store.state.club.ranks.find((x: any) => this.$store.state.club.members.find((x: any) => x.user == this.$store.state.userInformation._id).rank == x.id).name;
				} catch (e) {
					return "-";
				}
			} else {
				return "-";
			}
		},
		getUserImage() {
			if (this.$store.state.userInformation.imgPath) return this.$store.state.mainPath + this.$store.state.userInformation.imgPath;
			else return "https://pbs.twimg.com/profile_images/770139154898382848/ndFg-IDH_400x400.jpg";
		},
		getUserInformation(): any {
			return this.$store.state.userInformation;
		},
		isAdmin() {
			if (this.$store.state.club.ranks && this.$store.state.userInformation._id) {
				let user = this.$store.state.club.members.find((member: any) => {
					return member.user == this.$store.state.userInformation._id;
				});
				if (user) {
					return this.$store.state.club.ranks.find((rank: any) => rank.id == user.rank).isAdmin;
				} else return false;
			} else return false;
		}
	}
});
